@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
      --background: 0 0% 100% /* white */;
      --foreground: 229 84% 5% /* slate-950 */;
      --card: 0 0% 100% /* white */;
      --card-foreground: 229 84% 5% /* slate-950 */;
      --popover: 0 0% 100% /* white */;
      --popover-foreground: 229 84% 5% /* slate-950 */;
      --primary: 322 65% 55% /* hsl(322, 65%, 55%)*/;
      --primary-foreground: 328 74% 98% /* pink-50 */;
      --secondary: 53 92% 50% /* hsl(53 92% 50%) */;
      --secondary-foreground: 29 73% 26% /* yellow-900 */;
      --muted: 210 41% 97% /* slate-100 */;
      --muted-foreground: 216 17% 47% /* slate-500 */;
      --accent: 141 85% 93% /* green-100 */;
      --accent-foreground: 144 62% 21% /* green-900 */;
      --destructive: 0 85% 61% /* red-500 */;
      --destructive-foreground: 211 41% 99% /* slate-50 */;
      --border: 215 32% 92% /* slate-200 */;
      --input: 215 32% 92% /* slate-200 */;
      --ring: 334 72% 51% /* pink-600 */;
      --radius: 0.5rem;
  }

  .dark {
      --background: 229 84% 5% /* slate-950 */;
      --foreground: 211 41% 99% /* slate-50 */;
      --card: 229 84% 5% /* slate-950 */;
      --card-foreground: 211 41% 99% /* slate-50 */;
      --popover: 229 84% 5% /* slate-950 */;
      --popover-foreground: 211 41% 99% /* slate-50 */;
      --primary: 334 72% 51% /* pink-600 */;
      --primary-foreground: 328 74% 98% /* pink-50 */;
      --secondary: 32 81% 29% /* yellow-800 */;
      --secondary-foreground: 55 92% 96% /* yellow-50 */;
      --muted: 218 33% 18% /* slate-800 */;
      --muted-foreground: 216 21% 66% /* slate-400 */;
      --accent: 143 65% 25% /* green-800 */;
      --accent-foreground: 139 77% 97% /* green-50 */;
      --destructive: 0 63% 31% /* red-900 */;
      --destructive-foreground: 211 41% 99% /* slate-50 */;
      --border: 218 33% 18% /* slate-800 */;
      --input: 218 33% 18% /* slate-800 */;
      --ring: 334 72% 51% /* pink-600 */;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

